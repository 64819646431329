<template>
  <section class="checkout-sec">
    <div class="container">
      <div class="row">
        <div class="col-12 title text-center">
          <h3>{{ translationText.text.checkout }}</h3>
        </div>
        <div class="col-md-7 order-md-1 order-2">
          <div class="checkout-process">
            <form>
              <div class="form-content">
                <div class="label-heading label-heading-active">
                  <h4>{{ translationText.text.contact }}</h4>
                  <a
                    href="javascript: ;"
                    v-if="loginEditBtn"
                    @click="editLoginTab"
                    class="link"
                    >{{ translationText.text.edit }}</a
                  >
                </div>
                <div class="form-infomation">
                  <p>{{ translationText.text.email }} {{ email }}</p>
                </div>
                <template v-if="!userLogin.checkIsLoggedIn">
                  <Form novalidate @submit="signinValid" v-if="loginNextBtn">
                    <div class="form-input">
                      <div class="row">
                        <div class="col-md-8">
                          <div class="form-group input-icon">
                            <Field
                              type="email"
                              v-model="email"
                              name="email"
                              class="form-control"
                              rules="email|required"
                              :placeholder="translationText.text.emailText"
                            ></Field>
                            <img
                              src="/assets/images/check-full.png"
                              alt=""
                              class="i-check"
                            />
                            <ErrorMessage name="email" />
                          </div>
                        </div>
                        <div class="col-md-8" v-if="passwordBtn != false">
                          <div class="form-group input-icon">
                            <Field
                              type="password"
                              v-model="password"
                              name="password"
                              rules="required"
                              class="form-control"
                              :placeholder="translationText.text.passwordLabel"
                            ></Field>
                            <ErrorMessage name="password" />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <a
                          href="javascript: ;"
                          v-if="isLoginNextBtn"
                          @click="loginBtnclick"
                          class="btn btn-dark rc btn-checkout"
                          >{{ translationText.text.login }}</a
                        >
                        <button
                          v-if="isLoginNextBtn"
                          @click="guestCheckout"
                          type="button"
                          class="btn btn-dark rc btn-checkout"
                        >
                          {{ translationText.text.guestCheckout }}
                        </button>
                        <router-link
                          v-if="isLoginNextBtn"
                          :to="{ name: 'Signup' }"
                          class="btn btn-dark rc btn-checkout"
                          >Registration</router-link
                        >
                        <button
                          v-if="isLoginBtnclick"
                          type="submit"
                          class="btn btn-dark rc btn-checkout"
                        >
                          {{ translationText.text.next }}
                          <i class="ri-arrow-right-line"></i>
                        </button>
                      </div>
                    </div>
                  </Form>
                </template>
                <template v-else>
                  <div class="row" v-if="isLoginAfterNextBtn">
                    <button
                      type="button"
                      @click="afterLoginNext"
                      class="btn btn-dark rc btn-checkout"
                    >
                      {{ translationText.text.next }}
                      <i class="ri-arrow-right-line"></i>
                    </button>
                  </div>
                </template>
              </div>

              <div class="form-content">
                <div class="label-heading label-heading-active">
                  <h4>ADDRESS</h4>
                  <a
                    href="javascript: ;"
                    v-if="addressEditBtn"
                    @click="addressEdit"
                    class="link"
                    >{{ translationText.text.edit }}
                  </a>
                </div>
                <div class="form-infomation" v-if="addressEditBtn">
                  <h6>{{ translationText.text.deliveryAddress }}</h6>
                  <address>
                    {{ countryAddressShow }} {{ first_name }} {{ last_name }}
                    {{ address1 }} {{ addres2 }}, {{ city }},
                    {{ countryAddressShow }}
                    {{ zip_code }}
                    {{ mobile }}
                  </address>
                  <div class="options-highlited">
                    {{ translationText.text.sameDelivery }}
                  </div>
                </div>
                <Form novalidate @submit="signinAddressValid">
                  <div class="form-input" v-if="addressNextBtn">
                    <div class="row">
                      <div class="form-group col-12">
                        <label class="field">
                          <span class="field-label" for="country"
                            >{{ translationText.text.countryLabel }} *</span
                          >
                          <!-- @change="onChangeCountry($event)" -->
                          <Field
                            rules="required"
                            v-model="country"
                            as="select"
                            class="field-input form-control"
                            name="country"
                            id="country"
                          >
                            <!-- <option value="United States (US)">United States (US)</option>
                                                <option value="Canada">Canada</option> -->
                            <template v-if="allCountries.length > 0">
                              <template
                                v-for="(allCountry, index) in allCountries"
                              >
                                <option :value="allCountry.id">
                                  {{ allCountry.name }}
                                </option>
                              </template>
                            </template>
                          </Field>
                          <ErrorMessage name="country" />
                        </label>
                      </div>
                      <div class="form-group col-md-6">
                        <Field
                          type="text"
                          rules="required"
                          v-model="first_name"
                          name="first_name"
                          class="form-control"
                          :placeholder="translationText.text.firstNameLabel"
                        ></Field>
                        <ErrorMessage name="first_name" />
                      </div>
                      <div class="form-group col-md-6">
                        <Field
                          type="text"
                          rules="required"
                          v-model="last_name"
                          name="last_name"
                          class="form-control"
                          :placeholder="translationText.text.lastNameLabel"
                        ></Field>
                        <ErrorMessage name="last_name" />
                      </div>
                      <div class="form-group col-12">
                        <Field
                          type="text"
                          rules="required"
                          v-model="address1"
                          name="address1"
                          class="form-control"
                          :placeholder="translationText.text.address1Label"
                        ></Field>
                        <ErrorMessage name="address1" />
                      </div>
                      <div class="form-group col-12">
                        <Field
                          type="text"
                          name="addres2"
                          v-model="addres2"
                          class="form-control"
                          :placeholder="translationText.text.address2Label"
                        ></Field>
                        <!-- <ErrorMessage name="addres2" /> -->
                      </div>
                      <div class="form-group col-md-4">
                        <Field
                          type="text"
                          rules="required"
                          v-model="city"
                          name="city"
                          class="form-control"
                          :placeholder="translationText.text.cityLabel"
                        ></Field>
                        <ErrorMessage name="city" />
                      </div>
                      <div class="form-group col-md-4 col-6">
                        <Field
                          as="select"
                          rules="required"
                          v-model="state"
                          class="field-input form-control"
                          name="state"
                          id="state"
                        >
                          <option value="" disabled>
                            {{ translationText.text.stateLabel }}
                          </option>
                          <template v-if="allStates.length > 0">
                            <template v-for="(allState, index) in allStates">
                              <option :value="allState.id">
                                {{ allState.name }}
                              </option>
                            </template>
                          </template>
                        </Field>
                        <ErrorMessage name="state" />
                      </div>
                      <div class="form-group col-md-4 col-6">
                        <Field
                          type="text"
                          v-model="zip_code"
                          rules="required"
                          name="zip_code"
                          class="form-control"
                          :placeholder="translationText.text.zipcode"
                        ></Field>
                        <ErrorMessage name="zip_code" />
                      </div>
                      <div class="form-group col-md-8">
                        <Field
                          type="tel"
                          rules="required"
                          v-model="mobile"
                          name="mobile"
                          class="form-control"
                          :placeholder="translationText.text.phoneLabel"
                        ></Field>
                        <ErrorMessage name="mobile" />
                      </div>
                    </div>
                    <!-- <div class="form-group col-12">
                                    <div class="custome_checkbox">
                                        <input type="checkbox" id="bill-address" rows="1">  
                                        <label for="bill-address">My billing and delivery information are the same.</label>
                                    </div>
                                </div> -->
                    <div class="form-group col-12">
                      <div class="custome_checkbox">
                        <Field
                          
                          v-model="billSame"
                          :value="true"
                          type="checkbox"
                          id="bill-address"
                          name="billSame"
                          rows="1"
                        ></Field>
                        <label for="bill-address"
                          >{{ translationText.text.myBilling }}
                          <a href="#">{{
                            translationText.text.readMore
                          }}</a></label
                        >
                        <ErrorMessage name="billSame" />
                      </div>
                    </div>
                    <div class="form-group col-12">
                      <div class="custome_checkbox">
                        <Field
                          v-model="stayup"
                          :value="true"
                          type="checkbox"
                          id="stayup"
                          name="stayup"
                          rows="1"
                        ></Field>
                        <label for="stayup"
                          >{{ translationText.text.likeStay }}
                          <a href="#">{{
                            translationText.text.readMore
                          }}</a></label
                        >
                        <ErrorMessage name="stayup" />
                      </div>
                    </div>
                    <div class="form-group col-12">
                      <div class="custome_checkbox">
                        <Field
                          :value="true"
                          v-model="terms"
                          rules="required"
                          type="checkbox"
                          name="terms"
                          id="terms"
                          rows="1"
                        ></Field>
                        <label for="terms"
                          >{{ translationText.text.yes18 }}
                          <a href="#">{{ translationText.text.termsText }}</a>
                          {{ translationText.text.andtext }}
                          <a href="#">{{
                            translationText.text.privacyPolicy
                          }}</a
                          >*</label
                        >
                        <ErrorMessage name="terms" />
                      </div>
                    </div>
                  </div>
                  <button
                    v-if="addressNextBtn"
                    type="submit"
                    class="btn btn-dark rc"
                  >
                    {{ translationText.text.next }}
                    <i class="ri-arrow-right-line"></i>
                  </button>
                </Form>
              </div>
              <div class="form-content">
                <div class="label-heading label-heading-active">
                  <h4>{{ translationText.text.deliveryOption }}</h4>
                  <a
                    href="javascript: ;"
                    v-if="deliveryEditBtn"
                    @click="deliveryEdit"
                    class="link"
                    >{{ translationText.text.edit }}</a
                  >
                </div>
                <template v-if="deliveryNextBtn">
                  <div class="form-infomation">
                    <div class="options-highlited">
                      {{ translationText.text.payOnline }}
                    </div>
                  </div>
                  <Form novalidate @submit="deliveryOption">
                    <!-- <template v-if="shippingOptions.length > 0"> -->
                    <template
                      v-for="(shippingOption, index) in shippingOptions"
                    >
                      <div class="delivery-box">
                        <!-- Standard Delivery -->
                        <Field
                          rules="required"
                          @change="getShippingName(shippingOption)"
                          v-model="shippingType"
                          :value="shippingOption.rate"
                          type="radio"
                          name="shippingType"
                        ></Field>

                        <!-- <h6 class="mb-md-4 mb-3">{{ index }}</h6> -->
                        <i class="ri-truck-line"></i>
                        <h6>${{ shippingOption.rate }}</h6>
                        <p>
                          {{ shippingOption.carrier }} ({{
                            shippingOption.service
                          }})
                        </p>
                        <!-- <p>
                          {{ translationText.text.deliveryWithin }}

                          {{ shippingOption.delivery_days }}
                          {{ translationText.text.days }}
                        </p> -->
                      </div>
                    </template>
                    <ErrorMessage name="shippingType" />
                    <!-- </template> -->
                    <!-- <button v-if="deliveryNextBtn" @click="deliveryOption" type="button" class="btn btn-dark rc">NEXT <i class="ri-arrow-right-line"></i></button> -->
                    <button
                      v-if="deliveryNextBtn"
                      type="submit"
                      class="btn btn-dark rc"
                    >
                      {{ translationText.text.next }}
                      <i class="ri-arrow-right-line"></i>
                    </button>
                  </Form>
                </template>
              </div>

              <Payment
                :baseURL="props.baseURL"
                :paymentParentNextBtn="paymentParentNextBtn"
                :translationText="translationText"
                :email="email"
                :password="password"
                :country="country"
                :first_name="first_name"
                :last_name="last_name"
                :address1="address1"
                :addres2="addres2"
                :city="city"
                :state="state"
                :zip_code="zip_code"
                :mobile="mobile"
                :billSame="billSame"
                :stayup="stayup"
                :terms="terms"
                :shippingType="shippingType"
                :shippingTypeName="shippingTypeName"
              ></Payment>
            </form>
          </div>
        </div>
        <CheckoutProductReview
          :translationText="translationText"
          :baseURL="props.baseURL"
        ></CheckoutProductReview>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, computed, defineProps, watch, onMounted, watchEffect } from "vue";
import { Form, Field, ErrorMessage, defineRule, configure } from "vee-validate";

import { mapState, storeToRefs } from "pinia";
import { useLoginStore } from "../../stores/login";
import { useCartStore } from "../../stores/cart";
import Payment from "../../components/Checkout/Payment";
import CheckoutProductReview from "../../components/Checkout/CheckoutProductReview";
import { useRouter, useRoute, onBeforeRouteLeave, onBeforeRouteUpdate } from "vue-router";
import PasswordMeter from "vue-simple-password-meter";
//import { required, between, confirmed,email } from '@vee-validate/rules';
import * as AllRules from "@vee-validate/rules";
import { localize } from "@vee-validate/i18n";
import { userLanguage } from "../../stores/language";
import axios from "axios";

const props = defineProps(["baseURL"]);
Object.keys(AllRules).forEach((rule) => {
  defineRule(rule, AllRules[rule]);
});
const cartData = useCartStore();
const userLogin = useLoginStore();
const userLanguages = userLanguage();
const { languageCode } = storeToRefs(userLanguages);

const { loginToken } = storeToRefs(userLogin);

const headers = {
  Authorization: "Bearer " + userLogin.loggedToken,
};

onBeforeRouteLeave((to, from) => {
  if(cartData.countCartItems > 0 && (userLogin.checkUserType == 'customer' || userLogin.checkUserType == null)){
        const answer = window.confirm(
        'Do you really want to leave? you have unsaved changes!'
      )
      // cancel the navigation and stay on the same page
      if (!answer){
        window.$("#exitModal").modal("show");
        return false
        
      } 
      else{
        return true
      }
  }
  
   
})

configure({
  generateMessage: localize({
    en: {
      messages: {
        required: "This field is required",
      },
      fields: {
        email: {
          required: "Email address is required",
          email: "Please enter a valid email address.",
        },
        password: {
          required: "Password is required",
        },
        country: {
          required: "Select country",
        },
        state: {
          required: "Select state/province",
        },
      },
    },
  }),
});


const router = useRouter();
const route = useRoute();

const isLoginBtnclick = ref(false);
const passwordBtn = ref(false);
const loginNextBtn = ref(true);
const addressNextBtn = ref(false);
const deliveryNextBtn = ref(false);
const paymentParentNextBtn = ref(false);
const placeOrderBtn = ref(false);

const loginEditBtn = ref(false);
const addressEditBtn = ref(false);
const deliveryEditBtn = ref(false);
const isLoginNextBtn = ref(true);

const isLoginAfterNextBtn = ref(true);

const password = ref();
const email = ref();
const country = ref("");
const first_name = ref();
const last_name = ref();
const address1 = ref();
const addres2 = ref();
const city = ref();
const state = ref("");
const zip_code = ref();
const mobile = ref();
const stayup = ref();
const terms = ref();
const billSame = ref();
const allStates = ref([]);
const allCountries = ref([]);
const shippingOptions = ref([]);

const shippingType = ref();
const shippingTypeName = ref();

const countryAddressShow = ref();
const stateAddressShow = ref();

const defaultLanguage = ref("");

const headerNavigation = ref([]);
const siteLanguage = ref([]);


const loginBtnclick = async () => {
  passwordBtn.value = true;
  isLoginBtnclick.value = true;
  isLoginNextBtn.value = false;
};
const deliveryEdit = async () => {
  console.log("inside address");
  deliveryNextBtn.value = true;
  addressEditBtn.value = false;
};

const addressEdit = async () => {
  console.log("inside address");
  addressNextBtn.value = true;
  deliveryEditBtn.value = false;
};
const editLoginTab = async () => {
  console.log("inside address");
  loginNextBtn.value = true;
  loginEditBtn.value = false;
  isLoginBtnclick.value = false;
  isLoginNextBtn.value = true;
  passwordBtn.value = false;
};

const deliveryOption = async () => {
  if (shippingType.value != "") {
    console.log("inside address");
    addressNextBtn.value = false;
    deliveryNextBtn.value = false;
    paymentParentNextBtn.value = true;
    deliveryEditBtn.value = true;
    console.log(shippingType.value);
    cartData.addShippingRate(shippingType.value);
  } else {
    swal({
      position: "top-end",
      icon: "error",
      title: "Please select shipping method",
      button: false,
      timer: 2500,
    });
  }
};
const signinAddressValid = async () => {
  let indexCountry = allCountries.value.findIndex(
    (product) => product.id == country.value
  );

  let indexState = allStates.value.findIndex(
    (product) => product.id == state.value
  );
  //console.log("country.value")
  // allCountries.value[indexCountry]
  console.log("indexCountry ", indexCountry);
  console.log("indexCountry find ", allCountries.value[indexCountry].shortname);
  console.log("indexState ", indexState);
  const shippingPayLoads = ref({
    firstName: first_name.value,
    lastName: last_name.value,
    to_zip: zip_code.value,
    to_country: allCountries.value[indexCountry].shortname,    
    to_street: address1.value + " " + addres2.value,
    to_city: city.value,
    to_state: allStates.value[indexState].name,
    currency: userLogin.getUserCountry == "Canada" ? "CAD" : "USD",
    qty: cartData.getCartItems.length
  });
  console.log("shippingPayLoads", shippingPayLoads.value);
  axios
    .post(props.baseURL + "shipping-rates", shippingPayLoads.value)
    .then((response) => {
      //allStates.value = response.data.states;
      console.log("shipping  data", response.data);
      console.log("shipping  data", response.data.shipping_rates);
      if(response.data.shipping_rates.length > 0){
        shippingOptions.value = response.data.shipping_rates;

      addressNextBtn.value = false;
      deliveryNextBtn.value = true;
      addressEditBtn.value = true;

      }
      else{
         swal({
          position: "top-end",
          icon: "error",
          title: "No Shipping Method found. Please change address.",
          button: false,
          timer: 2500,
        });

      }
      
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });

  console.log("inside address");
};

const afterLoginNext = async () => {
  addressNextBtn.value = true;
  isLoginAfterNextBtn.value = false;
};

const guestCheckout = async () => {
  addressNextBtn.value = true;
  loginNextBtn.value = false;
  loginEditBtn.value = true;
};
const signinValid = async () => {
    const user = {
    email: email.value,
    password: password.value,
  };

  await axios
    //.post(`${props.baseURL}auth/login`, user)
    .post(props.baseURL + "login", user, {
      validateStatus: function (status) {
        return status < 500; // Resolve only if the status code is less than 500
      },
    })
    .then((res) => {
      console.log("response ", res.data);
      if (res.data.errors) {
        swal({
          position: "top-end",
          icon: "error",
          title: res.data.message,
          button: false,
          timer: 2500,
        });
      } else {
        userLogin.userLoggedIn(res.data);
        addressNextBtn.value = true;
        loginNextBtn.value = false;
        loginEditBtn.value = true;

        // Get address

        // Get addess ends here
      }

      //router.push({ name: "Dashboard" });
    })
    .catch((err) => {
      
    })
    .finally(() => {
      //loading = false;
    });
};

const getCountry = async () => {
  axios
    .get(props.baseURL + "countries")
    .then((response) => {
      allCountries.value = response.data.countries;
      console.log("country  data", response.data.countries);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
};
let isPageReloaded = false; 
//Add to cart functionality
const addProductToCart = async (productId) => {
  if (isPageReloaded) return; 
  const languageUrl = userLanguages.getLanguageCode
    ? "/" + userLanguages.getLanguageCode
    : "";
    const reqUserType = userLogin.checkUserType
    ? "/" + userLogin.checkUserType
    : "/" + "customer";
  try {
    // Fetch product data from the API
    const response = await axios.get(`${props.baseURL}product/${productId}${reqUserType}${languageUrl}`);
    const products = response.data.products;

    // Find the product by ID
    const product = products.find((prod) => prod.id === parseInt(productId));

    if (product) {
      // Access current cart items safely (considering Vue's reactivity)
      cartData.resetAllData();
      const currentCartItems = Array.isArray(cartData.getCartItems)
        ? cartData.getCartItems
        : cartData.getCartItems.value || [];

      console.log("Current cart items (debug):", currentCartItems);

      // Check if the product already exists in the cart
      const existingCartItem = currentCartItems.find(
        (item) => item.id === product.id
      );

      if (existingCartItem) {
        console.log(`Product ${productId} (${product.name}) is already in the cart.`);
        return; // Prevent adding the same product again
      }

      // Prepare the cart item object
      const cartItem = {
        id: product.id,
        name: product.name,
        sale_price: product.sale_price,
        price: product.sale_price,
        description: product.short_description,
        image: product.images_url.length > 0 ? product.images_url[0] : "",
        hoverImage: product.thumbnail_url,
        quantity: 1,
        purchaseType: product.purchaseType || 1,
      };

      // Add the new product to the cart
      cartData.addToCart(cartItem);
      console.log(`Product ${productId} (${product.name}) added to the cart.`);
      isPageReloaded = true;
     
    } else {
      console.error(`Product with ID ${productId} not found.`);
    }
  } catch (error) {
    console.error("Error adding product to cart:", error);
  }
};





const translationTextEn = ref({
  text: {
    checkout: "CHECKOUT",
    emailText: "Email address *",
    contact: "CONTACT",
    email: "Email",
    login: "Login",
    guestCheckout: "Guest Checkout",
    registration: "Registration",
    address: "ADDRESS",
    deliveryOption: "DELIVERY OPTIONS",
    payment: "PAYMENT",

    edit: "Edit",
    next: "Next",
    payOnline:
      "Pay Online for Faster Delivery and Faster Return/Refund. Please allow up to one extra day for packaging and handling.",
    deliveryWithin: "Estimate ",
    days: "Day(s) delivery",
    myBilling: "My billing and delivery information are the same.",
    readMore: "Read more",
    likeStay: "I would like to stay up to date with Cymcorp International.",
    yes18: "Yes, I am over 18 years old & accept the",
    andtext: "And",
    privacyPolicy: "Privacy Policy.",
    deliveryAddress: "Delivery address",
    sameDelivery: "Your delivery and billing address are the same",
    countryLabel: "Country",
    emailLabel: "Email Address",
    ambassadorLabel: "As an Ambassador: Create your own...",
    passwordLabel: "Password",
    confirmPasswordLabel: "Confirm Password",
    strengthLabel: "Strength",
    securityLabel: "Select Security Question",
    answerLabel: "Answer",
    selectLabel: "Select an option",
    SSNLabel: "Numbers Only, No Dashes",
    languageLabel: "Select Language",
    currencyLabel: "Default Currency",
    firstNameLabel: "First Name *",
    lastNameLabel: "Last Name *",
    phoneLabel: "Mobile Number *",
    birthLabel: "Birth Date",
    monthSelectLabel: "Select Month",
    dateSelectLabel: "Select Date",
    yearSelectLabel: "Select Year",
    yesSendLabel: "YES! SEND ME E-MAIL NOTICES & UPDATES",
    enrollmentSummaryLabel: "Enrollment Summary",
    countryBillillingAddressLabel: "Billing Address",
    address1Label: "Address line 1 *",
    address2Label: "Address line 2",
    cityLabel: "City *",
    countryLabel: "Country",
    stateLabel: "Select state/province",
    contrySelectLabel: "Select country",
    agreements: "Agreements",
    termsText: "Terms & Conditions",
    termsTextContent:
      'I have read and agree to the Terms and Conditions of this website. Clicking the "Place Order Now" button will submit my order for processing. I may cancel this purchase within 30 days for a full refund by eMailing support@cymcorp.ca or contacting Customer Service at',
    stateLabel1: "State",
    shippingAddressLabel: "Shipping Address",
    zipcode: "Zip Code*",
    sameAsBillingAddress: "Same As Billing Address",
    signUp: "Sign Up",
    creditCard: "Credit/Debit Cards",
    apply: "Apply",
    total: "Total",
    codeApplied: "Coupon Code Applied",
    subtotal: "Subtotal",
    netShipping: "Net Shipping",
    shippingTax: "Shipping Tax", 
    shipping_tax: "Shipping Tax", 
    tax: "Tax",
    volumeSummary: "Volume Summary",
    monthlyPE: "Total Monthly PE",
    enterCode: "Enter Coupon Code",
    paymentSsl:
      "Payments are SSL encrypted so that your credit card and payment details stay safe.",
    cardNumber: "Card number",
    expiration: "Expiration",
    cvv: "cvv",
    placeOrder: "Place Order",
  },
  language: "",
});


const translationText = ref({
  text: {
    checkout: "CHECKOUT",
    emailText: "Email address *",
    contact: "CONTACT",
    email: "Email",
    login: "Login",
    guestCheckout: "Guest Checkout",
    registration: "Registration",
    address: "ADDRESS",
    deliveryOption: "DELIVERY OPTIONS",
    payment: "PAYMENT",

    edit: "Edit",
    next: "Next",
    payOnline:
      "Pay Online for Faster Delivery and Faster Return/Refund. Please allow up to one extra day for packaging and handling.",
    deliveryWithin: "Estimate ",
    days: "Day(s) delivery",
    myBilling: "My billing and delivery information are the same.",
    readMore: "Read more",
    likeStay: "I would like to stay up to date with Cymcorp International.",
    yes18: "Yes, I am over 18 years old & accept the",
    andtext: "And",
    privacyPolicy: "Privacy Policy.",
    deliveryAddress: "Delivery address",
    sameDelivery: "Your delivery and billing address are the same",
    countryLabel: "Country",
    emailLabel: "Email Address",
    ambassadorLabel: "As an Ambassador: Create your own...",
    passwordLabel: "Password",
    confirmPasswordLabel: "Confirm Password",
    strengthLabel: "Strength",
    securityLabel: "Select Security Question",
    answerLabel: "Answer",
    selectLabel: "Select an option",
    SSNLabel: "Numbers Only, No Dashes",
    languageLabel: "Select Language",
    currencyLabel: "Default Currency",
    firstNameLabel: "First Name *",
    lastNameLabel: "Last Name *",
    phoneLabel: "Mobile Number *",
    birthLabel: "Birth Date",
    monthSelectLabel: "Select Month",
    dateSelectLabel: "Select Date",
    yearSelectLabel: "Select Year",
    yesSendLabel: "YES! SEND ME E-MAIL NOTICES & UPDATES",
    enrollmentSummaryLabel: "Enrollment Summary",
    countryBillillingAddressLabel: "Billing Address",
    address1Label: "Address line 1 *",
    address2Label: "Address line 2",
    cityLabel: "City *",
    countryLabel: "Country",
    stateLabel: "Select state/province",
    contrySelectLabel: "Select country",
    agreements: "Agreements",
    termsText: "Terms & Conditions",
    termsTextContent:
      'I have read and agree to the Terms and Conditions of this website. Clicking the "Place Order Now" button will submit my order for processing. I may cancel this purchase within 30 days for a full refund by eMailing support@cymcorp.ca or contacting Customer Service at',
    stateLabel1: "State",
    shippingAddressLabel: "Shipping Address",
    zipcode: "Zip Code*",
    sameAsBillingAddress: "Same As Billing Address",
    signUp: "Sign Up",
    creditCard: "Credit/Debit Cards",
    apply: "Apply",
    total: "Total",
    codeApplied: "Coupon Code Applied",
    subtotal: "Subtotal",
    netShipping: "Net Shipping",
    shippingTax: "Shipping Tax", 
    shipping_tax: "shipping_tax", 
    tax: "Tax",
    volumeSummary: "Volume Summary",
    monthlyPE: "Total Monthly PE",
    enterCode: "Enter Coupon Code",
    paymentSsl:
      "Payments are SSL encrypted so that your credit card and payment details stay safe.",
    cardNumber: "Card number",
    expiration: "Expiration",
    cvv: "cvv",
    placeOrder: "Place Order",
  },
  language: "",
});

const translatText = () => {
  
  translationText.value.language = userLanguages.getLanguageCode
    ? "/" + userLanguages.getLanguageCode
    : "";

  if(userLanguages.getLanguageCode != 'en'){
    const apiUrl = props.baseURL + "translateany";
    axios
      .post(apiUrl, translationText.value)
      .then((response) => {
        console.log("response trnasate :", response.data);
        translationText.value = response.data;
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }
  else{
    translationText.value = translationTextEn.value;
  }
  
};

const getUserAddress = () => {
  // console.log(props.baseURL);
  // const languageUrl = userLanguages.getLanguageCode
  //   ? "/" + userLanguages.getLanguageCode
  //   : "";

  const apiUrl = props.baseURL + "user/address/shipping";
  axios
    .get(apiUrl, { headers })
    .then((response) => {
      console.log(
        "response user/address/shipping :",
        response.data.shipping_address
      );
      //translationText.value = response.data;
      if (response.data.shipping_address) {
        country.value = response.data.shipping_address.country;
        first_name.value = userLogin.getUserUserData.firstname;
        last_name.value = userLogin.getUserUserData.lastname;
        address1.value = response.data.shipping_address.address1;
        addres2.value = response.data.shipping_address.address2;
        city.value = response.data.shipping_address.city;
        state.value = response.data.shipping_address.state;
        zip_code.value = response.data.shipping_address.zip_code;
        mobile.value = response.data.shipping_address.mobile;
      }
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
};

const getShippingName = (shippinVal) => {
  shippingTypeName.value = shippinVal.carrier;
  console.log("shippinVal.carrier", shippinVal.carrier);
};

const getLanguage = async () => {
  await axios
    .get(`${props.baseURL}languages`)
    //.get(apiUrl)
    .then((res) => {
      console.log("response ", res.data);
      siteLanguage.value = res.data.languages;

      if (userLanguages.getLanguageCode == null) {
        if (res.data.languages.length > 0) {
          res.data.languages.forEach((language) => {
            if (language.is_default == 1) {
              userLanguages.setLanguage(language);
              console.log("language", language);
              defaultLanguage.value = language.code;
            }
          });
        }
      }
    })
    .catch((err) => {
      //   swal({
      //     text: "Unable to Log you in!",
      //     icon: "error",
      //     closeOnClickOutside: false,
      //   });
      console.log(err);
    })
    .finally(() => {
      //loading = false;
    });
};
//get Header
let isHeaderFetched = false;
const getHeader = async () => {
  if (isHeaderFetched) return; 
  isHeaderFetched = true;

  const languageUrl = userLanguages.getLanguageCode
    ? "/" + userLanguages.getLanguageCode
    : "";
  const apiUrl = props.baseURL + "navigations/header" + languageUrl;
  await axios
    .get(apiUrl)
    .then((res) => {
      console.log("response ", res.data);
      headerNavigation.value = res.data.navigations;
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
    });
};

watch(country, (newValue, oldValue) => {
  console.log("countrt selected");

  if (allCountries.value.length > 0) {
    let countryIndex = allCountries.value.findIndex(
      (country) => country.id == newValue
    );
    countryAddressShow.value = allCountries.value[countryIndex].name;
  }

  

  axios
    .get(props.baseURL + "states/" + newValue)
    .then((response) => {
      allStates.value = response.data.states;
      console.log("states  data", response.data.states);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
});

watch(state, (newValue, oldValue) => {
  if (allStates.value.length > 0) {
    let stateIndex = allStates.value.findIndex(
      (country) => country.id == newValue
    );
    console.log("allStates stateIndex", stateIndex);
    console.log("allStates.value", allStates.value);
    stateAddressShow.value = allStates.value[stateIndex].name;
  }

  axios
    .get(props.baseURL + "tax/" + newValue)
    .then((response) => {
      //allStates.value = response.data.states;
      cartData.addTaxData(response.data);
      console.log("states tax data", response.data);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
});

watch(languageCode, () => {
  console.log("isLoggedIn ref changed, do something!");
  translatText();
});

watch(loginToken, () => {
  
  const headers = {
  Authorization: "Bearer " + userLogin.loggedToken,
};

  const apiUrl = props.baseURL + "user/address/shipping";
  axios
    .get(apiUrl, { headers })
    .then((response) => {
      console.log(
        "response user/address/shipping :",
        response.data.shipping_address
      );
      //translationText.value = response.data;
      if (response.data.shipping_address) {
        country.value = response.data.shipping_address.country;
        first_name.value = userLogin.getUserUserData.firstname;
        last_name.value = userLogin.getUserUserData.lastname;
        address1.value = response.data.shipping_address.address1;
        addres2.value = response.data.shipping_address.address2;
        city.value = response.data.shipping_address.city;
        state.value = response.data.shipping_address.state;
        zip_code.value = response.data.shipping_address.zip_code;
      }
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
});
onMounted(() => {
  // Get the product ID from the URL query parameter
  const productId = route.query.product;
  const lang = route.query.lang;
  if (productId) {
    addProductToCart(productId);
    userLanguages.setLanguage({ code: lang });
    defaultLanguage.value = lang;
    getHeader(); // Update navigation headers
    console.log(`Language set to: ${lang}`);
  
    getLanguage();
  }
  getCountry();
  translatText();
  if (userLogin.getUserUserData != null) {
    email.value = userLogin.getUserUserData.email;
    getUserAddress();
  } 
   

});

watch(() => route.query.lang, (newLang) => {
  if (newLang) {
    userLanguages.setLanguage({ code: newLang });
    defaultLanguage.value = newLang;
    getHeader(); // Update navigation headers
    console.log(`Language updated to: ${newLang}`);
  }
  getLanguage();
});
</script>

<!-- <script>

import CheckoutProductReview from '../../components/Checkout/CheckoutProductReview';
import Payment from '../../components/Checkout/Payment';
export default {
    components : {CheckoutProductReview,Payment},
  data() {
    return {
      stripeAPIToken: process.env.VUE_APP_STRIPETOKEN,
      stripe: '',
      token: null,
      sessionId: null,
      checkoutBodyArray: [],
    };
  },

  name: 'Checkout',
  props: ['baseURL'],
  methods: {
    /*
      Configures Stripe by setting up the elements and
      creating the card element.
    */
    // configureStripe() {},

    // getAllItems() {
    //   axios.get(`${this.baseURL}cart/?token=${this.token}`).then(
    //     (response) => {
    //       if (response.status == 200) {
    //         let products = response.data;
    //         let len = Object.keys(products.cartItems).length;
    //         for (let i = 0; i < len; i++)
    //           this.checkoutBodyArray.push({
    //             imageUrl: products.cartItems[i].product.imageURL,
    //             productName: products.cartItems[i].product.name,
    //             quantity: products.cartItems[i].quantity,
    //             price: products.cartItems[i].product.price,
    //             productId: products.cartItems[i].product.id,
    //             userId: products.cartItems[i].userId,
    //           });
    //       }
    //     },
    //     (err) => {
    //       console.log(err);
    //     }
    //   );
    // },

    // goToCheckout() {
    //   axios
    //     .post(
    //       this.baseURL + 'order/create-checkout-session',
    //       this.checkoutBodyArray
    //     )
    //     .then((response) => {
    //       localStorage.setItem('sessionId', response.data.sessionId);
    //       return response.data;
    //     })
    //     .then((session) => {
    //       return this.stripe.redirectToCheckout({
    //         sessionId: session.sessionId,
    //       });
    //     });
    // },
  },
  mounted() {
    // get the token
    this.token = localStorage.getItem('token');
    // get all the cart items
    //this.stripe = Stripe(this.stripeAPIToken);
    console.log("stripe token",this.stripeAPIToken);
    //this.getAllItems();
  },
};
</script> -->

<style>
.alert {
  width: 50%;
}

.div_class {
  margin-top: 5%;
  margin-left: 30%;
}

.checkout_button {
  background-color: #5d3dec;
  border: none;
  color: white;
  margin-left: 15%;
  padding: 15px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  font-weight: bold;
  border-radius: 15px;
}

.checkout_button:focus {
  outline: none;
  box-shadow: none;
}

.checkout_button:disabled {
  background-color: #9b86f7;
  border: none;
  color: white;
  margin-left: 15%;
  padding: 15px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  font-weight: bold;
  border-radius: 15px;
  cursor: not-allowed;
}
.btn-checkout {
  margin-left: 9px;
  float: left;
  width: 175px;
}
</style>
